<template>
  <div id="page-container">
    <TopNav/>
    <!-- Let's not do ads on this page <FDFullWidthBanner/> -->
    <div class="createProviderAccount">

      <h1 class="text-center">Create your account</h1>
      <div class="w-4/5 mx-auto">
        <p class="text-center">Thanks so much for your interest in using our free volunteer management software! We want to help you set up an account that serves your charity's unique needs. Please click the link below to go to our Google Form. After you complete this survey, someone from Charity Quest will reach out to you to complete your organizaton's profile.</p>
        <div class="flex flex-row justify-evenly pb-10">
          <button
            class="bg-gray-200 hover:bg-green-300 text-gray-800 font-bold py-2 px-4 mx-auto rounded border-none"
            @click="
              e => {
                handleButtonClick(e);
                close(e);
              }
            "
          >
            <img
              class="w-4 h-4 mr-2"
              alt="A little pencil, meant for filling out a survey."
              src="../assets/navicons/CQ-Navicon_Pencil.png"
            />
            <span>Create an account for your charity</span>
          </button>
        </div>
        <br>
        <br>
        <br>
        <br>
        <br>
      </div>
      <!-- TODO <CreateProviderAccountForm/> -->
      
    </div>
    <Footer/>
  </div>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
// import FDFullWidthBanner from "../components/advertisements/FDFullWidthBanner.vue";
// import CreateChariteerAccountForm from "@/components/CreateChariteerAccountForm.vue";
import Footer from "@/components/Footer.vue";



export default {
  name: "CreateProviderAccount",
  components: {
    TopNav,
    // FDFullWidthBanner,
    // CreateChariteerAccountForm,
    Footer
  },
  methods: {
    handleButtonClick ( e )
    {
      console.log("Creating a new provider account...");
      window.open("https://docs.google.com/forms/d/e/1FAIpQLScm0wQbaScisfVObNpk7Kuk-W5PWS5xUTSbOXmeqKR0uqdsdw/viewform?usp=sf_link", '_blank', 'noreferrer');
    }
  }
};
</script>

<style scoped>

#page-container {
  background-color: #FFA94E;
  position: relative;
  min-height: 100vh;
}

h1 {
  color: white;
  margin-top: 50px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 50px;
}

.createProviderAccount {
  padding-bottom: 150px;    /* This is for the footer */
  margin: 0px auto;         /* Set the app to be centered */
}

@media only screen and (max-width: 650px) {
  .createChariteerAccount {
    width: 100%;
  }
}
</style>
